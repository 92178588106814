/** 
 * 列表渲染类                   【如果这里出现了问题，希望您保持耐心，作者有点菜写的不够好，注释我尽可能加全了】
 * 1、分发请求到各个供应商
 * 2、返回结果去重：一个页面请求四次，列表数据不重复
 * 3、按日期缓存列表数据：优先使用内存中的列表数据，保证同时请求多个页面相同日期，列表不混乱，并缓存日期的列表数据
 * 4、查漏补缺：请求三家供应商，两家成功一家失败，重新进入当前页面时拉取失败的供应商数据
 * 5、数据清洗：提供给页面可用的数据
 * 
 *  提供给外部的方法：
 *  resetList()  清空缓存，需要手动清，该类不会主动清空缓存（不光刷新，还有上滑加载更多）
 *  update()    更新当天列表，参数参考下方注释
 *  sort(cb())  排序
 *  
 *  提供外部的属性：
 *  list： 可以渲染的数组 ⭐⭐不要用vue监听数组
 *  supplier: 那些供应商已经返回了
 *  check：这个东西变化说明列表有更新  ⭐⭐监听这个
 */
import { DiffDate } from "@/services/date"
import { searchPosition, sortList } from "@/services/hotel"
import moment from "moment";
import { getTrainTicket } from "@/api/train"
import { getHotelList } from "@/api/hotel";
import { getFlightTicket, getChangeFlightTicket } from "@/api/aircraft"


class CommonList {
    _tempList = {
        // date：{ 日期做key
        // supplier[] 供应商列表，有数据的供应商会加入该列表，认为他成功
        // supplierNum 供应商数量，成功失败都会自增，用来做单次请求完成的检查
        // list 列表
        // }
    }
    date = ""   // 缓存的key
    check = 0   // 因为vue监听数组并不可靠，所以当我们希望被外部监听到变化时（成功失败）就修改他。

    // list 是提供给外部 可以直接用来展示的列表
    set list(newArr) {
        return console.error('list是只读变量,为保证缓存稳定,请使用update或者sort函数来修改它')
    }
    get list() {
        if (Object.prototype.hasOwnProperty.call(this._tempList, this.date) && Object.prototype.hasOwnProperty.call(this._tempList[this.date], 'list'))
            return this._tempList[this.date].list
        return []
    }
    get supplier() {
        if (Object.prototype.hasOwnProperty.call(this._tempList, this.date) && Object.prototype.hasOwnProperty.call(this._tempList[this.date], 'list'))
            return this._tempList[this.date].supplier
        return []
    }
    get supplierNum() {
        if (Object.prototype.hasOwnProperty.call(this._tempList, this.date) && Object.prototype.hasOwnProperty.call(this._tempList[this.date], 'list'))
            return this._tempList[this.date].supplierNum
        return 0
    }

    /**
     * _templist的set函数（核心，维护缓存） 当缓存key中有供应商数据会被丢弃，如果需要强制追加使用forcAppend参数
     * @param {Array} newList 新的数组
     * @param {String} key 
     * @param {String} corp 供应商
     * @param {Bollean} forcAppend 强制追加
     */
    setTempList(newList, key = this.date, corp, forcAppend) {
        // 如果缓存对象中没有key
        if ((!Object.prototype.hasOwnProperty.call(this._tempList, key) || !Object.prototype.hasOwnProperty.call(this._tempList[key], 'list'))) {
            this._tempList[key] = {
                supplier: (0 in newList) ? [corp] : [],
                supplierNum: 1,
                list: newList
            }
        }
        // 缓存中有key但是key中没有这个供应商，追加
        else {
            if (!this._tempList[key].supplier.includes(corp) || forcAppend) {
                this._tempList[key].supplierNum++
                if (0 in newList) {
                    this._tempList[key].supplier.push(corp)
                    this._tempList[key].list.push(...newList)
                }
            }
        }
        this.check++
    }

    /**
     * 更新缓存
     * @param {string} type aircraft:飞机  train：火车  hotel：酒店
     * @param {Object} query ajax查询条件 改签时需要指定corp。其余情况自动分发
     * @param {Array<String>} suppliers 供应商，这里有谁就给谁发
     * @param {Bollean} isNormal 是否正常购票（改签true）   ⭐机票改签专用
     */
    update(type, query, suppliers = [], isNormal = true) {
        return new Promise((resolve, reject) => {
            let date = (query.depDate || query.fromDate || query.checkInDate).toString() // 日期key

            // 如果当天有数据就过滤一下 留下的是之前缺失的供应商，酒店不做缓存
            if (type != 'hotel' && this._tempList[date] && this._tempList[date].supplier.length > 0)
                suppliers = suppliers.filter(e => !this._tempList[date].supplier.includes(e))
            // 设置新的key
            this.date = date
            // 循环分发
            suppliers.forEach(async (corp) => {
                let key = this.date // 记录一下key
                let result
                switch (type) {
                    case 'aircraft':
                        // 目前只在改签中携带指定供应商
                        if (!isNormal && query.corp && query.corp == corp) result = await fetchChangeAircraftList(JSON.stringify(query))
                        if (isNormal) {
                            query.corp = corp
                            result = await fetchAircraftList(JSON.stringify(query))
                        }
                        result = result || []
                        this.setTempList(result, key, corp)
                        resolve(true)
                        break;
                    case 'train':
                        query.corp = corp
                        result = await fechTrainList(JSON.stringify(query))
                        this.setTempList(result, key, corp)
                        resolve(true)
                        break;
                    case 'hotel':
                        searchPosition(query.key, query.city, async position => {
                            (`${query.city} 坐标：(${position.lng},${position.lat})`)
                            query.latitude = position.lat
                            query.longitude = position.lng
                            query.corp = corp
                            let req = JSON.stringify(query)
                            result = await fetchHotelList(req, position)
                            this.setTempList(result, key, corp, true)
                            resolve(true)
                        })

                }
            })
            this.check++
        })
    }

    // 排序，控制层定制排序方法，OrganizeAircraftList再分离一下无票的机票
    sort(cb = () => { }, type = "aircraft") {
        let tempList = this._tempList[this.date].list
        let result = []
        switch (type) {
            case 'aircraft':
                result = OrganizeAircraftList([{ data: tempList.sort(cb) }])
                break;
            case 'train':
                result = tempList.sort(cb)
                break;
            case 'hotel':
                result = sortList(tempList, cb)

        }
        this._tempList[this.date].list = result
        // this.check++
    }

    // 下拉刷新当前列表
    resetList() {
        this._tempList[this.date] = {}
    }

    // 对其supplierNum、supplier
    itsSupplierNum() {
        if (this._tempList[this.date] && this._tempList[this.date].supplierNum) this._tempList[this.date].supplierNum = this._tempList[this.date].supplier.length
    }

}

// 获得实例
export function init() { return new CommonList }


/****************
***下方次要逻辑***
****************/

// 获取机票列表ajax接口
function fetchAircraftList(query) {
    return new Promise(function (resolve, reject) {
        let req = JSON.parse(query)
        getFlightTicket(req).then(response => {
            let list = OrganizeAircraftList(response)
            resolve(list)
        }).catch(error => {
            resolve([])
        })
    })
}

// 获取改签列表ajax接口
function fetchChangeAircraftList(query) {
    return new Promise(function (resolve, reject) {
        let req = JSON.parse(query)
        req.depDate = moment(req.depDate).format('YYYY-MM-DD')
        getChangeFlightTicket(req).then(response => {
            let list = OrganizeAircraftList([response])
            resolve(list)
        }).catch(error => {
            resolve([])
        })
    })
}

// 获取火车列表ajax接口
function fechTrainList(query) {
    return new Promise(function (resolve) {
        let req = JSON.parse(query)
        getTrainTicket(req).then(response => {
            let list = OrganizeTrainList(response)
            resolve(list)
        }).catch(error => {
            resolve([])
        })
    })
}

// 获取酒店列表ajax接口
export function fetchHotelList(query) {
    return new Promise(function (resolve) {
        let req = JSON.parse(query)
        getHotelList(req).then(response => {
            let list = OrganizeHotelList(response)
            resolve(list)
        }).catch(error => {
            resolve([])
        })
    })
}

/**
 * 整理机票列表
 * @param {Array} arr 
 */
function OrganizeAircraftList(arr = []) {
    let dataArr = []
    arr.forEach(element => {
        let deadArr = []
        // 如果是数组遍历,过滤掉无座机票，保留无票坐席
        element.data = element.data.filter(ticket => {
            let arrtemp = ticket.flightSeat.filter(seat => seat.number != 0)
            if (!ticket.flightSeat || !(0 in arrtemp)) {
                ticket.canBuyMinPrice = ""
                deadArr.push(ticket)
                return false
            }
            return true

        })
        element.data.forEach(ticket => {
            let arrtemp = ticket.flightSeat.filter(seat => seat.number != 0)
            let min = ""
            if (0 in arrtemp)
                min = arrtemp.sort((a, b) => a.price.seatPrice - b.price.seatPrice)[0].price.seatPrice
            ticket.canBuyMinPrice = min.toString()
        })
        dataArr.push(...element.data, ...deadArr)
    });
    return dataArr
}

/**
 * 整理火车票列表
 * @param {Array} arr 
 */
function OrganizeTrainList(arr = []) {
    let dataArr = []
    arr.forEach(element => {
        element.data.map(item => {
                try {
                if (Object.prototype.hasOwnProperty.call(item, 'displayPrice') && item.displayPrice == "") {
                    item.displayPrice = item.displaySeat[0].seatPrice
                }
                if (Object.prototype.hasOwnProperty.call(item, 'arriveDays') && item.arriveDays == "") {
                    item.arriveDays = DiffDate(item.trainBeginDate + "T" + item.startTime, item.trainEndDate + "T" + item.arriveTime).toString()
                }
                return dataArr.push(item)
            } catch (error) {
                return false
            }
        })
    })
    return dataArr
}

// 整理酒店列表
function OrganizeHotelList(list = []) {
    let arr = []
    list.forEach(first => {
        arr.push(...first.data)
    });
    return arr
}